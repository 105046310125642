import React from "react";
import Logo from "./LogoSmall";

const Intro = (props) => {
  const { primary, secondary, teaser, children } = props;
  return (
    <>
      <div className="intro">
        <Logo />
        <h1>{primary}</h1>
        <p>{secondary}</p>
        <h2 className="intro_teaser">{teaser}</h2>
      </div>
      {children && <h3>{children}</h3>}
    </>
  );
};

export default Intro;
