import React from "react";
import check from "../Images/check.webp";

const Bullet = (props) => {
  const { content, dollars, secondary } = props;
  return (
    <li className={secondary ? "secondary_li" : null}>
      {dollars ? null : (
        <img
          src={check}
          alt="check"
          className={secondary ? "secondary_check" : "check"}
        />
      )}
      <span>{content}</span>
    </li>
  );
};

export default Bullet;
