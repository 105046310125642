import React from "react";
import Intro from "../Components/Intro";
import Example from "../Components/Example";
import { Link } from "react-router-dom";

import b2b from "../Images/b2b.png";
import streamline from "../Images/streamline.png";
import website from "../Images/website.png";

const ExperienceManagement = () => {
  return (
    <div className="main">
      <Intro
        primary="Website and Software ExperienceManagement"
        secondary="Sleek. Focused. Calculated."
        teaser="What do you want to build?"
      />
      <div className="examples" style={{ maxWidth: "54rem" }}>
        <Example src={website} alt="business website">
          <h3>Business Website</h3>
          <span className="o64">
            Represent your company online with a website that attracts and
            converts.
          </span>
          <Link to="/business-website" className="link">
            SELECT
          </Link>
        </Example>
        <Example src={streamline} alt="streamline operations">
          <h3>Internal Software</h3>
          <span className="o64">
            Streamline internal operations and processes with custom software.
          </span>
          <Link to="/" className="link">
            SELECT
          </Link>
        </Example>
        <Example src={b2b} alt="business-to-business software">
          <h3>Business-to-Business Software</h3>
          <span className="o64">
            Design, develop, and deploy custom software that you can sell to
            customers.
          </span>
          <Link to="/" className="link">
            SELECT
          </Link>
        </Example>
        {/* <SiteBundle />
        <SiteBundle large />
        <SiteStoking />
        <SpecialtyServices /> */}
      </div>
    </div>
  );
};

export default ExperienceManagement;
