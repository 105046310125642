import React from "react";
import Example from "../Example";
import { Link } from "react-router-dom";

import xm from "../../Images/xm.png";
import graphicDesign from "../../Images/graphicDesign.png";
import website from "../../Images/website.png";

const Services = () => {
  return (
    <div className="examples">
      <Example src={xm} alt="Mantle logo + heart">
        <h3>Experience Management Training</h3>
        <span className="o64">
          The art and science of identifying, validating, and fulfilling the
          emotional needs of every customer, every time.
        </span>
        <div
          style={{ opacity: 0.64 }}
          to="/experience-management"
          className="link"
        >
          COMING SOON
        </div>
      </Example>
      <Example
        src={website}
        alt="Laptop icon with the internet symbol in the middle of the screen and the Mantle logo in the top left."
      >
        <h3>Website and Software Development</h3>
        <span className="o64">
          The websites and software we build are sleek, calculated, and focused
          on making personal connections with users.
        </span>
        <Link to="/development" className="link">
          LEARN MORE
        </Link>
      </Example>
      <Example
        src={graphicDesign}
        alt="Several logos, including the Mantle logo (an M with a circle over it), Crystal Clear Consulting logo (a sideways-lying gemstone), Skallywag Media logo (cartoonish skull and crossbones)"
      >
        <h3>Graphic Design</h3>
        <span className="o64">
          The logos, icons, and graphics we create will communicate your brand's
          personality clearly and beautifully.
        </span>
        <div
          style={{ opacity: 0.64, cursor: "default" }}
          to="/graphic-design"
          className="link"
        >
          COMING SOON
        </div>
      </Example>
    </div>
  );
};

export default Services;
