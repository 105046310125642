import React from "react";
import logo from "../Images/logo.png";
import { Link } from "react-router-dom";

const LogoSmall = () => {
  return (
    <Link to="/" className="logo_container">
      <img className="logo" src={logo} alt="M Logo" />
      <span>
        Mantle <span className="o64">Experience Management</span>
      </span>
    </Link>
  );
};

export default LogoSmall;
