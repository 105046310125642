import React from "react";
// import { Link } from "react-router-dom";

import Intro from "../Components/Intro";
import Example from "../Components/Example";
import AddToCart from "../Components/AddToCart";
import Bullet from "../Components/Bullet";
// import H2H3 from "../Components/H2H3";

import ignition from "../Images/ignition.png";
import stoking from "../Images/stoking.png";
import sustaining from "../Images/sustaining.png";
import H2H3 from "../Components/H2H3";
// import tending from "../Images/tending.png";
// import streamline from "../Images/streamline.png";
// import b2b from "../Images/b2b.png";

const BusinessWebsite = () => {
  return (
    <div className="main">
      <Intro
        primary="Put your best foot forward."
        secondary="Full-service website and software development services."
        teaser="What are your primary goals?"
      />
      <div className="examples">
        <Example src={ignition} alt="business website">
          <div className="center">
            <h3>Build a Website</h3>
            <i className="o64">
              Represent your brand online with a website that converts.
            </i>
          </div>
          <ul>
            <Bullet content="Full-Service Design, Development, and Deployment" />
            <Bullet content="Written Content, Graphics, and Basic Functionality Included" />
            <Bullet content="Websites, Web Applications, Ecommerce" />
            <Bullet content="Stay Confident and Informed Every Step of the Way" />
          </ul>
          <AddToCart item="Build a Website" />
        </Example>
        <Example src={stoking} alt="business website">
          <div className="center">
            <h3>Promote a Website</h3>
            <i className="o64">
              Get your website in front of the right eyes at the right time.
            </i>
          </div>
          <ul>
            <Bullet content="Pay-Per-Click Ad Campaigns" />
            <Bullet content="A/B Testing" />
            <Bullet content="Trend Research and Analysis" />
            <Bullet content="Blog Post and Email Creation" />
            <Bullet content="Search Engine Optimization (SEO)" />
          </ul>
          <AddToCart item="Promote a Website" />
        </Example>
        <Example src={sustaining} alt="business website">
          <div className="center">
            <h3>Maintain a Website</h3>
            <i className="o64">
              Keep your website alive and keep a close eye on it.
            </i>
          </div>
          <ul>
            <Bullet content="Google Analytics Management" />
            <Bullet content="Google Search Console Management" />
            <Bullet content="Google Business Profile Management" />
            <Bullet content="Comprehensive Site Audits" />
            <Bullet content="Website Impact and Performance Emails" />
            <Bullet content="Expert Advice to Improve Your Website" />
            <Bullet content="Any Changes You and Your Customers Want" />
          </ul>
          <AddToCart item="Maintain a Website" />
        </Example>
      </div>
    </div>
  );
};

export default BusinessWebsite;
