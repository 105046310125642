import React from "react";
import Intro from "../Components/Intro";
import CheckoutForm from "../Components/Form/CheckoutForm";

const Checkout = () => {
  return (
    <div className="main">
      <Intro
        primary="You're interested. We're interested."
        secondary="Let's get in touch. Our team can answer any questions you have and work with you to build, maintain, and promote a website that you and your customers love."
        teaser="Reach out:"
      />
      <CheckoutForm />
    </div>
  );
};

export default Checkout;
