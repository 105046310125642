import React from "react";
import footerLogo from "../Images/FooterLogo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <img className="footer_logo" src={footerLogo} alt="Mantle XM" />
      <div>
        <h3>Navigate Our Site</h3>
        <nav>
          <Link to="/">Home</Link>
          {/* <Link to="/">Experience Management Training</Link> */}
          <Link to="/development">Website and Software Development</Link>
          <Link to="/check-out">Check Out</Link>
          {/* <Link to="/">Graphic Design</Link> */}
          {/* <Link to="/standard-website-bundle">Standard Website Bundle</Link>
          <Link to="/large-website-bundle">Large Website Bundle</Link>
          <Link to="/site-stoking">Site Stoking Bundle</Link>
          <Link to="/specialty-services">Specialty Services</Link> */}
        </nav>
      </div>
      <div>
        <h3>Contact Us</h3>
        <nav>
          <a href="tel:801-867-8774">801-867-8774</a>
          <a href="mailto:contact@mantle-xm.com?subject=You've Sparked My Interest">
            contact@mantle-xm.com
          </a>
        </nav>
      </div>
      {/* <div> */}
      {/* <h3>Follow Us</h3> */}
      {/* <nav></nav> */}
      {/* </div> */}
      {/* <div>
          <h3>Follow Us</h3>
        </div> */}
    </footer>
  );
};

export default Footer;
