import React from "react";
import flame from "../../Images/Flame.png";

const Checkbox = (props) => {
  const { checked, onChange, name } = props;

  return (
    <button type="button" className="checkbox_container" onClick={onChange}>
      <img
        src={flame}
        className={checked ? "checkbox_checked" : "checkbox_unchecked"}
        alt="flame"
      />
      {/* <div style={{ display: "flex", flexFlow: "column" }}> */}
      <span
        style={{ textAlign: "left" }}
        className={checked ? "label_checked" : "label"}
      >
        {name}
      </span>
      {/* <i className="checked_description">({description})</i> */}
      {/* </div> */}
    </button>
  );
};

export default Checkbox;
