import React from "react";
import Intro from "../Components/Intro";
import Bullet from "../Components/Bullet";
import H2H3 from "../Components/H2H3";
import AddToCart from "../Components/AddToCart";
import Example from "../Components/Example";

const CheckedOut = () => {
  return (
    <div className="main">
      <Intro
        primary="Thank you."
        secondary="We'll be in touch soon."
        teaser="Explore Our Services:"
      >
        {/* <span className="o64">
          Explore the other services we have to offer.
        </span> */}
      </Intro>
      <div className="examples">
        <Example>
          <div className="center">
            <h3>Sitemap Creation and Wireframing</h3>
            <i className="o64">
              Structure your website in a way that users find intuitive and
              appealing.
            </i>
          </div>
          <ul>
            <Bullet content="Determine what information is most valuable to customers visiting your website." />
            <Bullet content="Decide which website pages and page sections will show off your business and brand most effectively." />
            <Bullet content="Develop a website structure that serves your most valuable content in a convenient and connecting way." />
          </ul>
          <AddToCart item="Sitemap Creation and Wireframing" />
        </Example>
        <Example>
          <div className="center">
            <h3>Site Design and Style Guide Creation</h3>
            <i className="o64">
              Design sleek website pages, page sections, and templates. Here are
              some pages or sections that might fit the needs of your website:
            </i>
          </div>
          <ul>
            <Bullet content="Home" />
            <Bullet content="About" />
            <Bullet content="Contact" />
            <Bullet content="Portfolio" />
            <Bullet content="Product(s) and Service(s)" />
            <Bullet content="Checkout" />
            <Bullet content="Post-Checkout" />
            <Bullet content="Blog" />
            <Bullet content="Blog Post" />
            <Bullet content="Landing Page" />
            <Bullet content="Privacy Policy" />
            <Bullet content="Terms and Conditions" />
            <Bullet content="404 (Page Not Found)" />
            <Bullet content="...and More!" />
          </ul>
          <AddToCart item="Site Design and Style Guide Creation" />
        </Example>
        <Example>
          <div className="center">
            <h3>Content Creation</h3>
            <i className="o64">
              Give your website some flare with visuals and copy that convert.
            </i>
          </div>
          <ul>
            <Bullet content="Logos, Icons, and Graphics" />
            <Bullet content="Photography and Videography" />
            <Bullet content="Written Copy" />
          </ul>
          <AddToCart item="Content Creation" />
        </Example>
        <Example>
          <div className="center">
            <h3>Website Optimization</h3>
            <i className="o64">
              Optimize your website for the following key metrics:
            </i>
          </div>
          <ul>
            <Bullet content="Accessibility" />
            <Bullet content="Best Practices" />
            <Bullet content="Performance" />
            <Bullet content="SEO" />
            <Bullet content="Speed" />
          </ul>
          <AddToCart item="Website Optimization" />
        </Example>
        <Example>
          <div className="center">
            <h3>Add Functionality</h3>
            <i className="o64">
              Make your website more useful to you and your customers.
            </i>
          </div>
          <ul>
            <Bullet content="Send Emails" />
            <Bullet content="Submit and Save Forms" />
            <Bullet content="Schedule Appointments" />
            <Bullet content="Collect User Information" />
            <Bullet content="Manage Cookies" />
            <Bullet content="Provide Downloadable Content" />
          </ul>
          <AddToCart item="Add Functionality" />
        </Example>
        <Example>
          <div className="center">
            <h3>Basic Maintenance</h3>
            <i className="o64">
              Keep your website alive and available to customers.
            </i>
          </div>
          <ul>
            <Bullet content="DNS Hosting" />
            <Bullet content="SSL/TLS Ceertificate Management" />
            <Bullet content="Standard Website Updates" />
            <Bullet content="Expert Advice to Improve Your Website" />
          </ul>
          <AddToCart item="Basic Maintenance" />
        </Example>
        <Example>
          <div className="center">
            <h3>Advanced Maintenance</h3>
            <i className="o64">
              Keep your website alive and available to customers.
            </i>
          </div>
          <ul>
            <Bullet content="DNS Hosting" />
            <Bullet content="SSL/TLS Ceertificate Management" />
            <Bullet content="Standard Website Updates" />
            <Bullet content="Expert Advice to Improve Your Website" />
            <Bullet content="Google Search Console Management" />
            <Bullet content="Google Business Profile Management" />
            <Bullet content="Google Analytics Management" />
            <Bullet content="Keyword and Trend Research" />
            <Bullet content="Data-Driven Website Updates" />
            <Bullet content="Sitemap Management" />
            <Bullet content="Comprehensive Site Audits" />
            <Bullet content="Website Performance and Impact Summary Emails" />
          </ul>
          <AddToCart item="Basic Maintenance" />
        </Example>
      </div>
    </div>
  );
};

export default CheckedOut;
