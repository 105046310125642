import React from "react";

const H2H3 = (props) => {
  const { primary, children } = props;
  return (
    <div className="H2H3">
      {primary && <h2 style={{ marginBottom: "1rem" }}>{primary}</h2>}
      <h3>{children}</h3>
    </div>
  );
};

export default H2H3;
