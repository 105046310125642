import React, { createContext, useCallback, useState } from "react";

const SnackbarContext = createContext({
  primary: "",
  secondary: "",
  clearSnackBar: () => {},
  showSnackBar: () => {},
});

export const SnackbarContextProvider = (props) => {
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");

  const clearSnackBar = useCallback(() => {
    setPrimary("");
    setSecondary("");
  }, [setPrimary, setSecondary]);

  const showSnackBar = useCallback(
    (primary, secondary, time) => {
      setPrimary(primary);
      setSecondary(secondary);

      setTimeout(() => {
        clearSnackBar();
      }, time || 5000);
    },
    [setPrimary, setSecondary, clearSnackBar]
  );

  const contextValue = {
    primary,
    secondary,
    clearSnackBar,
    showSnackBar,
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      {props.children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
