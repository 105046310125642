import React, { useContext } from "react";
import SnackbarContext from "../Context/snackbar";

const Snackbar = () => {
  const { primary, secondary, clearSnackBar } = useContext(SnackbarContext);

  if (primary) {
    return (
      <div className="snackbar" onClick={clearSnackBar}>
        <h3>{primary}</h3>
        {secondary && <h4>{secondary}</h4>}
      </div>
    );
  } else return null;
};

export default Snackbar;
