import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import CartContext from "../../Context/cart";

import Input from "./Input";
import Checkbox from "./Checkbox";
import SnackbarContext from "../../Context/snackbar";

const CheckoutForm = () => {
  let navigate = useNavigate();
  const scrollRef = useRef(null);
  const { items, allItems, addItem, clearCart, deleteItem } =
    useContext(CartContext);
  const { showSnackBar } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [website, setWebsite] = useState("");

  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [itemsError, setItemsError] = useState("");

  function validate(body) {
    const { fullName, email, number, website } = body;

    let valid = true;

    if (!fullName || typeof fullName !== "string") {
      setFullNameError("Enter your name.");
      valid = false;
    }

    if (!email || typeof email !== "string") {
      setEmailError("Enter your email.");
      valid = false;
    }

    if (!number || typeof number !== "string") {
      setNumberError("Enter your phone number.");
      valid = false;
    }

    if (!website || typeof website !== "string") {
      setWebsiteError("Enter your website URL.");
      valid = false;
    }

    if (!items || typeof items !== "object" || items.length === 0) {
      setItemsError("Select at least one item.");
      valid = false;
    }

    if (!valid) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      throw {
        message: "Invalid input.",
        instructions: "Please fix the errors with the form.",
      };
    }
  }

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let body = {
      fullName,
      email,
      number,
      website,
      items,
      timestamp: new Date(),
    };

    try {
      validate(body);
      await fetch(
        "https://backend-dot-mantle-xm.wm.r.appspot.com/api/form/contact",
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      clearCart();
      navigate("/checked-out", { replace: true });
    } catch (error) {
      showSnackBar(error.message, error.instructions);
    }
    setLoading(false);
  };

  return (
    <form ref={scrollRef} onSubmit={submit} className="form">
      <Input
        type="text"
        label="What is your full name?"
        placeholder="Full Name"
        value={fullName}
        onChange={(e) => {
          setFullName(e.target.value);
          setFullNameError("");
        }}
        error={fullNameError}
      />
      <Input
        type="email"
        label="What is your email?"
        placeholder="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailError("");
        }}
        error={emailError}
      />
      <Input
        type="tel"
        label="What is your phone number?"
        placeholder="Number"
        value={number}
        onChange={(e) => {
          setNumber(e.target.value);
          setNumberError("");
        }}
        error={numberError}
      />
      <Input
        type="text"
        label="What is your business' website URL?"
        placeholder="Website"
        value={website}
        onChange={(e) => {
          setWebsite(e.target.value);
          setWebsiteError("");
        }}
        error={websiteError}
      />
      <div>
        <label className={itemsError ? "label_error" : ""}>
          {itemsError ? itemsError : "What are your primary goals?"}
        </label>
        {allItems
          .sort((a, b) => (allItems[a]?.name < allItems[b]?.name ? -1 : 1))
          .map((item, index) => {
            const { name, description } = item;
            return (
              <Checkbox
                key={index}
                onChange={() => {
                  setItemsError("");
                  items.includes(name) ? deleteItem(name) : addItem(name);
                }}
                checked={items.includes(name)}
                name={name}
                description={description}
              />
            );
          })}
      </div>
      <button type="submit" onSubmit={submit}>
        {loading ? "PLEASE WAIT..." : "SUBMIT"}
      </button>
    </form>
  );
};

export default CheckoutForm;
