import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// import Development from "./Development";
import Home from "./Home";
import ExperienceManagement from "./ExperienceManagement";
// import SpecialtyServices from "./SpecialtyServices";
// import SiteStoking from "./SiteStoking";
// import SiteBundle from "./SiteBundle";
import FourZeroFour from "./FourZeroFour";
import Checkout from "./Checkout";
import CheckedOut from "./CheckedOut";
import BusinessWebsite from "./BusinessWebsite";

export const ScrollToTop = () => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

export default (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/experience-management" element={<ExperienceManagement />} />
    {/* <Route path="/" element={<BusinessWebsite />} /> */}
    <Route path="/development" element={<BusinessWebsite />} />
    {/* <Route path="/standard-website-bundle" element={<SiteBundle />} />
    <Route path="/large-website-bundle" element={<SiteBundle large />} /> */}
    {/* <Route path="/site-stoking" element={<SiteStoking />} /> */}
    {/* <Route path="/specialty-services" element={<SpecialtyServices />} /> */}
    <Route path="/check-out" element={<Checkout />} />
    <Route path="/checked-out" element={<CheckedOut />} />
    {/* <Route path="/business-website" element={<BusinessWebsite />} /> */}
    <Route path="*" element={<FourZeroFour />} />
  </Routes>
);
