import React, { createContext, useCallback, useState } from "react";

const CartContext = createContext({
  items: [],
  allItems: [],
  addItem: () => {},
  clearCart: () => {},
  deleteItem: () => {},
});

export const CartContextProvider = (props) => {
  const initialItems = JSON.parse(localStorage.getItem("items")) || [];
  const [items, setItems] = useState(initialItems);

  const allItems = [
    {
      name: "Build a Website",
      description:
        "Represent your business online with a website that attracts and converts.",
    },
    {
      name: "Promote a Website",
      description:
        "Get your website in front of the right eyes at the right time.",
    },
    {
      name: "Maintain a Website",
      description: "Keep your website alive—and keep a close eye on it.",
    },
    // {
    //   name: "Site Sustaining",
    //   description:
    //     "Keep your website alive and available to customers worldwide.",
    // },
  ];

  const addItem = useCallback(
    (itemID) => {
      setItems((previousState) => {
        let newItemArray = [...previousState, itemID];
        localStorage.setItem("items", JSON.stringify(newItemArray));
        return newItemArray;
      });
    },
    [setItems]
  );

  const clearCart = useCallback(() => {
    setItems([]);
    localStorage.setItem("items", JSON.stringify([]));
  }, [setItems]);

  const deleteItem = useCallback(
    (itemID) => {
      setItems((previousState) => {
        let noItemArray = previousState.filter((id) => id !== itemID);

        localStorage.setItem("items", JSON.stringify(noItemArray));
        return noItemArray;
      });
    },
    [setItems]
  );

  const contextValue = {
    items,
    allItems,
    addItem,
    clearCart,
    deleteItem,
  };

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContext;
