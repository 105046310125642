import React from "react";

const Example = (props) => {
  const { alt, src, children } = props;
  return (
    <div className="example">
      <div className="example_image_container">
        <img src={src} alt={alt} className="example_image" />
      </div>
      <div className="example_children">{children}</div>
    </div>
  );
};

export default Example;
