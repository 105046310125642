import React from "react";
import "./App.css";
import routes, { ScrollToTop } from "./Pages/routes";
import Footer from "./Components/Footer";
import Cart from "./Components/Cart";
import Snackbar from "./Components/Snackbar";

function App() {
  return (
    <div className="App">
      <Snackbar />
      <ScrollToTop />
      <Cart />
      {routes}
      <Footer />
      {/* <div className="spark" /> */}
    </div>
  );
}

export default App;
