import React from "react";
import Intro from "../Components/Intro";
import Services from "../Components/Examples/Services";

const FourZeroFour = () => {
  return (
    <div className="main">
      <Intro
        primary="Hm..."
        secondary="We couldn't find this page."
        teaser="Where to Go From Here"
      />
      <span className="o64">Here is a list of the services we offer:</span>
      <Services />
    </div>
  );
};

export default FourZeroFour;
