import React, { useContext } from "react";
import CartContext from "../Context/cart";
import { Link, useLocation } from "react-router-dom";

const Cart = () => {
  let location = useLocation();
  const { items } = useContext(CartContext);

  if (items.length > 0 && location.pathname !== "/check-out") {
    return (
      <Link
        style={{
          position: "fixed",
          zIndex: 10,
          margin: "1rem",
          top: 0,
          right: 0,
        }}
        className="link"
        to="/check-out"
      >
        CHECK OUT ({items.length})
      </Link>
    );
  } else return null;
};

export default Cart;
