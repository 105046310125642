import React, { useContext } from "react";
import CartContext from "../Context/cart";

const AddToCart = (props) => {
  const { item, fullPage } = props;
  const { addItem, deleteItem, items } = useContext(CartContext);
  const added = items.includes(item);

  let pageStyle = { zIndex: 5 };
  if (fullPage) {
    pageStyle = {
      position: "fixed",
      bottom: 0,
      right: 0,
      margin: "1rem",
      marginBottom: "2rem",
      zIndex: 5,
    };
  }

  let button = (
    <button
      onClick={() => {
        addItem(item);
      }}
      style={pageStyle}
      className="cart_add"
    >
      ADD TO CART
    </button>
  );

  if (added) {
    button = (
      <button
        onClick={() => {
          deleteItem(item);
        }}
        className="cart_added"
        style={pageStyle}
      >
        REMOVE
      </button>
    );
  }

  return button;
};

export default AddToCart;
