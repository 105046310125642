import React from "react";

const Input = (props) => {
  const { error, label, onChange, placeholder, type, value } = props;
  return (
    <div className={error ? "error_input_container" : "input_container"}>
      <label>{error || label}</label>
      <input
        onChange={onChange}
        type={type}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
