import React from "react";
import Intro from "../Components/Intro";
import H2H3 from "../Components/H2H3";
import Example from "../Components/Example";
import Services from "../Components/Examples/Services";
import IVF from "../Components/IVF";

const Home = () => {
  return (
    <>
      <Intro
        primary="Every experience matters."
        secondary="Make them count."
        teaser="Here's how:"
      >
        <span className="o64">
          With every need for a product or service come related
        </span>
        <span className="oj_light"> emotional needs</span>
        <span className="o64">.</span>
      </Intro>
      <div className="examples">
        <Example>
          <div className="c_text">
            <span className="o64">People often feel</span>
            <span className="oj_light"> anxious </span>
            <span className="o64">when they go to the dentist.</span>
          </div>
        </Example>
        <Example>
          <div className="c_text">
            <span className="o64">People often feel</span>
            <span className="oj_light"> proud </span>
            <span className="o64">when they go to the gym.</span>
          </div>
        </Example>
        <Example>
          <div className="c_text">
            <span className="o64">People often feel</span>
            <span className="oj_light"> stressed </span>
            <span className="o64">when they schedule a massage.</span>
          </div>
        </Example>
      </div>
      <div className="IVFs_container">
        <h3 className="IVFs_header">
          The most effective brands identify, validate, and fulfill those needs.
        </h3>
        <div className="IVFs">
          <IVF
            primary="Identify"
            secondary="the emotions that your customers and clients are feeling—as a whole
					and on an individual basis."
          />
          <IVF
            primary="Validate"
            secondary="their emotions in authentic ways that promote genuine connection—and
					lasting brand loyalty."
          />
          <IVF
            primary="Fulfill"
            secondary="their emotional needs during every interaction—in person, online, or
					anywhere in-between."
          />
        </div>
      </div>
      <H2H3 primary="We can help with that.">
        <span className="o64">It's what we do. Here's what we can do</span>
        <span className="oj_light"> for you</span>
        <span className="o64">:</span>
      </H2H3>
      <Services />
    </>
  );
};

export default Home;
